@import 'utilities/references.scss';

.container {
    position: relative;

    .banner {
        width: 100%;
        @include app-bp-md(height, 410px, 160px);
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .transition-art {
        height: 350px;
        width: 100%;
        display: flex;
        overflow-x: visible;
        align-items: center;
        justify-content: center;
        > img {
            @include app-pre-bp(md) {
                width: 300%;
                height: 100%;
                object-fit: cover;
            }
            @include app-bp(md) {
                height: auto;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .services-container {
        position: relative;
        margin-bottom: 130px;
        @include app-pre-bp(md) {
            margin-top: 100px;
            margin-bottom: 100px;
        }

        .services-svg {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -410px;
            z-index: -1;
            opacity: 0.3;
        }

        .services-layout {
            display: grid;
            @include app-bp-md(grid-template-columns, repeat(2, calc(50% - 10px)), repeat(1, 100%));
            @include app-bp-md(gap, 20px, 12px);

            .service-card {
                background-color: #ffffff;
                border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
                box-shadow: 0 8px 40px 0 rgba($color: #000000, $alpha: 0.05);
                border-radius: 20px;
                padding: 16px;

                > span {
                    min-width: 78px;
                    max-width: 78px;
                    height: 78px;
                    border-radius: 10px;
                    background-color: rgba($color: $primary, $alpha: 0.1);
                }
                > div {
                    width: 100%;
                    overflow: hidden;
                    > p:last-child {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .faq {
        margin-bottom: 160px;
    }

    .hidden {
        bottom: -70px;
    }
}

.fab {
    bottom: 20px !important;
    left: 24px !important;
    width: 60px !important;
    height: 60px !important;
    position: fixed !important;
    :global(.ant-float-btn-icon) {
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.market {
    > div {
        display: flex;
        align-items: center;
        > p {
            flex: 1;
            width: 100px;
            padding: 0 8px;
            direction: ltr;
            &:nth-child(1) {
                @include rtl {
                    text-align: left;
                }
                @include ltr {
                    text-align: right;
                }
            }
            &:nth-child(2) {
                @include rtl {
                    text-align: right;
                }
                @include ltr {
                    text-align: left;
                }
            }
        }
        > img {
            display: flex;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
        }
    }
    > span {
        display: block;
        font-size: 24px;
    }
}
