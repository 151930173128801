@import '../references.scss';

.ant-collapse {
    background: none !important;
    border: none !important;

    .ant-collapse-item {
        .ant-collapse-header {
            .ant-collapse-expand-icon {
                > svg {
                    color: $dark3;
                    transition: 0.2s;
                }
            }
        }

        background-color: #fff !important;
        border-radius: 20px;
        border: 1.5px solid $dark6 !important;
        margin: 10px;
        padding: 6px 0;
        &:last-child {
            border-radius: 20px !important;
            .ant-collapse-content,
            .ant-collapse-content-active {
                border-radius: 20px !important;
            }
            @include app-pre-bp(md) {
                margin: 0;
            }
        }
        @include app-pre-bp(md) {
            margin: 10px 0;
        }

        &.ant-collapse-item-active {
            box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
            .ant-collapse-header-text {
                color: $primary !important;
            }
            .ant-collapse-header {
                .ant-collapse-expand-icon {
                    > svg {
                        transform: rotate(-90deg);
                        color: $primary !important;
                    }
                }
            }
        }

        .ant-collapse-content,
        .ant-collapse-content-active {
            border-radius: 20px;
            border: none !important;
        }

        .ant-collapse-content {
            .ant-collapse-content-box {
                > p {
                    font-size: 14px;
                    font-weight: 400;
                    color: $dark2 !important;
                    text-align: justify;
                }
            }
        }

        .ant-collapse-header-text {
            font-weight: 500;
            font-size: 16px;
            color: $dark1;
        }
    }
}
