@import 'utilities/references.scss';

.hiring-card {
    padding: 24px 24px;
    border-radius: 20px;
    border: 1px solid rgba($dark5, 0.25);
    background-color: white;

    .brand {
        @include app-pre-bp(lg) {
            width: 100%;
        }

        > img {
            border-radius: 10px;
            border: 1px solid rgba($dark5, 0.25);
            object-fit: cover;

            @include app-pre-bp(md) {
                max-width: 36px;
                max-height: 36px;
            }
        }
    }
}
