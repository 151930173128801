@import 'utilities/references.scss';

.hiring {
    // height: 531px;
    @include app-bp(md) {
        background-color: #ffffff;
    }
    border-radius: 0 0 20px 20px;
    @include app-bp-md(padding, 32px 0 48px, 0);

    > p {
        text-align: center;
        @include app-bp(md) {
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }

    .slider-content {
        overflow: hidden;
        min-height: 347px;
    }

    .loading-box {
        height: 347px;
    }

    .arrow-btn {
        > span {
            min-width: 26px;
            max-width: 26px;
            height: 26px;
            border-radius: 8px;
            background-color: $primary;
            &.disabled {
                background-color: rgba($color: $primary, $alpha: 0.25);
            }
        }
    }

    .category-list {
        position: relative;
        overflow: hidden;

        .parent-category {
            padding-bottom: 5px;
            overflow: auto;
            gap: 12px;
            height: 48px;

            @include app-pre-bp(md) {
                margin-top: 24px;
            }

            &::after,
            &::before {
                content: '';
                position: absolute;
                height: 100%;
                top: 0;
                z-index: 1;
                pointer-events: none;
                @include app-bp-md(width, 40px, 28px);
            }

            &::before {
                @include app-bp-md(width, 28px, 18px);
            }

            @include ltr {
                &::after {
                    @include app-bp-md(right, 32px, 0);
                    background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                }

                &::before {
                    @include app-bp-md(left, 32px, 0);
                    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                }
            }

            @include rtl {
                &::after {
                    @include app-bp-md(left, 32px, 0);
                    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                }
                &::before {
                    @include app-bp-md(right, 32px, 0);
                    background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
                }
            }

            .list-items {
                min-width: fit-content;
                background-color: #fff;
                border: 1.5px solid $dark6;
                border-radius: 20px;
                padding: 8px 20px 6px 20px;
                cursor: pointer;
                overflow: hidden;
                position: relative;

                > p {
                    user-select: none;
                }

                &.active {
                    position: relative;
                    background-color: $primary;
                    > p {
                        color: #fff;
                    }

                    &::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        bottom: -4px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        background-color: $primary;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .child-category {
            overflow-x: auto;
            overflow-y: hidden;
            gap: 32px;
            height: 54px;
            border-radius: 20px;
            border: 1.5px solid rgba($dark5, 0.25);
            background-color: white;

            .list-items {
                min-width: fit-content;
                position: relative;
                transition: 0.3s;
                border-radius: 12px;
                background-color: white;
                cursor: pointer;

                > p {
                    color: $primary;
                    user-select: none;
                }

                &.active {
                    background-color: $primary;
                    padding: 4px 8px;

                    > p {
                        color: white;
                    }
                }

                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 14px;
                    top: 14px;
                    background-color: rgba($dark5, 0.25);
                    left: -24px;

                    @include ltr {
                        left: unset;
                        right: -24px;
                    }
                }
            }
        }
    }
}
