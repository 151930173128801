@import 'utilities/references.scss';

.housing {
    padding: 32px 0;
    > p {
        text-align: center;
        @include app-bp(md) {
            padding-right: 2rem;
            padding-left: 2rem;
        }
    }
    .btn {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        .list-items {
            min-width: fit-content;
            background-color: #fff;
            border: 1.5px solid $dark6;
            border-radius: 20px;
            padding: 8px 20px 6px 20px;
            cursor: pointer;
            overflow: hidden;
            position: relative;

            > p {
                user-select: none;
            }

            &.active {
                position: relative;
                background-color: $primary;
                > p {
                    color: #fff;
                }

                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    background-color: $primary;
                    transform: rotate(45deg);
                }
            }
        }
    }
    .arrow-btn {
        > span {
            min-width: 26px;
            max-width: 26px;
            height: 26px;
            border-radius: 8px;
            background-color: $primary;
            &.disabled {
                background-color: rgba($color: $primary, $alpha: 0.25);
            }
        }
    }
    .houses-loading-box {
        height: 425px;
    }

    .tenants-loading-box {
        height: 275px;
    }
}
