@import 'utilities/references.scss';

.slider-layout {
    position: relative;
    z-index: 10;
    width: 100%;
    @include app-bp(md) {
        border-radius: 20px;
        margin: -180px auto 0 auto;
        border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
        background-color: white;
        box-shadow: 0 8px 48px 0 rgba($color: #000000, $alpha: 0.05);
    }
    @include app-pre-bp(md) {
        margin: -36px auto 0 auto;
    }

    .header {
        @include app-bp(md) {
            background-color: $fieldBG;
            border-radius: 20px 20px 0 0;
            padding: 10px 12px;
            border-bottom: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
        }
        @include app-pre-bp(md) {
            margin: 0 -2px;
            background-color: white;
            border-radius: 20px;
            padding: 12px 12px;
            border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
            box-shadow: 0 8px 40px 0 rgba($color: #000000, $alpha: 0.05);
        }
        > ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            @include app-bp(md) {
                gap: 16px;
            }

            > li {
                white-space: nowrap;
                @include app-bp(md) {
                    flex: 1;
                    height: 56px;
                }
                @include app-pre-bp(md) {
                    display: flex;
                    flex-direction: column;
                }

                > p {
                    @include app-bp(md) {
                        font-size: 18px;
                        font-size: 1.125rem;
                        font-weight: 500;
                    }
                    @include app-pre-bp(md) {
                        font-size: 0.75rem;
                        font-size: 12px;
                    }
                }

                &.active {
                    @include app-bp(md) {
                        background-color: rgba($primary, 0.1);
                        border-radius: 12px;
                    }

                    > p {
                        color: $primary;
                        display: block;
                    }
                }
            }
        }
    }

    .coming-soon {
        padding: 32px 0;
        > div {
            width: 570px;
            height: 370px;
            > img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
