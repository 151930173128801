@import 'utilities/references.scss';
.bests-container {
    @include app-bp-md(margin-bottom, 140px, 60px);
    @include app-bp(md) {
        background-color: white;
        border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
        border-radius: 20px;
    }

    .root-rank-hover-area:hover *::before,
    .root-rank-hover-area.pause *::before {
        animation-play-state: paused !important;
    }

    .bests-top-section {
        position: relative;
        background-color: #ffffff;
        @include app-bp-md(padding, 64px 32px, 24px);
        @include app-pre-bp(md) {
            border-radius: 20px;
            border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
        }

        > p {
            width: 100%;
            max-width: 680px;
        }

        > p,
        h1 {
            @include app-pre-bp(md) {
                margin-bottom: 32px;
            }
            z-index: 2;
        }

        .best-svg {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .stars {
            z-index: 2;
            gap: 20px;

            > svg {
                // box-shadow: 0 8px 24px 0 rgba($color: $primary, $alpha: 0.2);
                filter: drop-shadow(0 8px 24px 0 rgba($color: $primary, $alpha: 0.2));
            }
        }

        .search-input {
            width: 100%;
            max-width: 360px;
            z-index: 2;
        }
    }

    .bests-layout {
        .skeleton {
            width: 100%;
            height: 85px;
            border-radius: 20px !important;
            display: block !important;
            margin-bottom: 12px !important;
            overflow: hidden !important;
            > * {
                width: 100% !important;
            }
        }
        .best {
            background-color: rgba($color: #ffb02e, $alpha: 0.15);
            border-radius: 20px;

            > h4 {
                color: #ffb02e;
            }
        }
    }

    .pagination-layout {
        @include app-pre-bp(md) {
            background-color: #ffffff;
        }
        .pagination {
            width: 100%;
            @include app-pre-bp(md) {
                border-radius: 20px;
                padding: 3px 0;
                border: 1.5px solid $dark6;
            }
        }
    }
}
