@import 'utilities/references.scss';

.animated-text {
    .animation::after {
        font-weight: 300;
        content: '|';
        color: $dark4;
        animation: cursor 1.1s infinite step-start;
    }
    div[class~='ant-divider'] {
        width: calc(100% - 96px) !important;
        min-width: unset !important;
    }
    > img {
        position: relative;
        top: -5px;

        &.img-1 {
            left: 5px;
            @include ltr {
                left: unset;
                right: 5px;
                transform: scaleX(-1);
            }
        }
        &.img-2 {
            transform: scaleX(-1);
            right: 5px;
            @include ltr {
                right: unset;
                left: 5px;
                transform: scaleX(1);
            }
        }
    }
}

@keyframes cursor {
    50% {
        opacity: 0;
    }
}
