@import 'utilities/references.scss';

.collapse-header-content {
    .right-section {
        @include app-bp-md(gap, 16px, 8px);
        .ad,
        .index {
            min-width: 32px;
            max-width: 32px;
            height: 32px;
            border-radius: 10px;
            background-color: $fieldBG;
        }

        .best {
            min-width: 32px;
            max-width: 32px;
            height: 32px;
            border-radius: 10px;
            background-color: #ffb02e;
        }

        .image {
            border-radius: 10px;
            @include app-pre-bp(md) {
                width: 40px;
                height: 40px;
            }
            @include app-bp(md) {
                width: 50px;
                height: 50px;
            }

            > img {
                border-radius: 10px;
                object-fit: cover;
                @include app-pre-bp(md) {
                    width: 40px;
                    height: 40px;
                }
                @include app-bp(md) {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    .left-section {
        gap: 12px;

        @include app-pre-bp(md) {
            flex-direction: column-reverse;
        }

        .up,
        .down {
            min-width: 72px;
            max-width: 72px;
            min-height: 42px;
            max-height: 42px;
            border-radius: 10px;
            border: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
            cursor: pointer;
        }

        .up:hover {
            background: rgba($color: $primary, $alpha: 0.1);
        }
        .down:hover {
            background: rgba($color: $red, $alpha: 0.1);
        }

        .up-active,
        .up-active:hover {
            background-color: $primary;
            > p {
                color: #fff;
            }
        }
        .down-active,
        .down-active:hover {
            background-color: $red;
            > p {
                color: #fff;
            }
        }
    }

    .mobile-actions {
        justify-content: space-between;
        > button {
            color: $dark4;
            background-color: transparent;
            display: flex;
            gap: 8px;
            border: none;
            align-items: center;
            font-size: 16px;
        }
        .actions {
            display: flex;
            border: 1.5px solid #c7d1d640;
            border-radius: 10px;
            overflow: hidden;
            height: 36px;
            > * {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
            }
            > button {
                background-color: transparent;
                border: none;
                padding: 0 0.7rem;
            }
            > p {
                padding: 0 0.7rem;
            }
            > button:first-child {
                color: $red;
                &:hover {
                    background-color: #f443361a;
                }
                @include rtl {
                    border-left: 1.5px solid #c7d1d640;
                }
                @include ltr {
                    border-right: 1.5px solid #c7d1d640;
                }
                &.active {
                    background-color: $red;
                    color: white;
                }
            }
            > button:last-child {
                color: $primary;
                &:hover {
                    background-color: #009de01a;
                }
                @include rtl {
                    border-right: 1.5px solid #c7d1d640;
                }
                @include ltr {
                    border-left: 1.5px solid #c7d1d640;
                }
                &.active {
                    background-color: $primary;
                    color: white;
                }
            }
        }
    }
}

.collapse-body-layout {
    .desc-brand {
        text-align: justify;
        border-bottom: 1.5px solid rgba($color: $dark5, $alpha: 0.25);
    }

    > div {
        > div > p {
            text-wrap: nowrap;
        }
        > p {
            text-wrap: nowrap;
        }
    }
}

.footer-tools {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
    @include app-pre-bp(md) {
        flex-direction: column;
        align-items: start;
    }
}

.review-action {
    margin-top: 3rem;
    display: flex;
    gap: 16px;
    @include app-bp(md) {
        align-items: center;
        > * {
            flex: 1;
        }
    }
    @include app-pre-bp(md) {
        flex-direction: column-reverse;
        > * {
            width: 100%;
        }
    }
}
