@import 'utilities/references.scss';

.drag-able-scroll {
    position: relative;
    cursor: grab;

    @include app-pre-bp(md) {
        padding: 0;
    }

    &.scrolling {
        cursor: grabbing;
    }

    > div {
        transition: 0.3s;
        opacity: 1;

        &::-webkit-scrollbar {
            height: 0;
        }
    }

    &.hide > div {
        opacity: 0;
        height: 0;
        margin: 0;
    }

    .scroll-icon {
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 10;

        &.left {
            left: 8px;

            @include ltr {
                left: unset;
                right: 8px;
            }
        }

        &.right {
            right: 8px;

            @include ltr {
                right: unset;
                left: 8px;
            }
        }
    }

    &:hover .scroll-icon {
        opacity: 1;
    }
}
