@import 'utilities/references.scss';

.service-card {
    width: 265px;
    height: 315px;
    background-color: #ffffff;
    border: 1.5px solid $dark6;
    border-radius: 20px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);

    .image-layout {
        width: 100%;
        height: 200px;
        position: relative;

        > img {
            border-radius: 20px 20px 0 0;
            object-fit: cover;
        }

        > span {
            min-width: 44px;
            height: 36px;
            gap: 12px;
            border-radius: 10px;
            background-color: $primary;
            position: absolute;
            top: 16px;
            left: 16px;
            transition: width 0.2s;
            padding: 0 6px;

            &.extra-service {
                max-width: calc(100% - 32px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                > p {
                    padding: 6px 8px;
                }
            }

            > p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .empty-state {
            background-color: rgba($dark3, 0.1);
        }

        .bg-cost-star {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 57px;
            background: rgb(0, 0, 0);
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);

            > p {
                gap: 5px;
            }
        }
    }

    .brand-layout {
        height: 115px;
        .brand-name {
            > img {
                border-radius: 7px;
            }
        }

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
