@import 'utilities/references.scss';

.category-list {
    position: relative;
    overflow: hidden;

    .parent-category {
        padding-bottom: 5px;
        overflow: auto;
        gap: 12px;
        height: 48px;
        &.loading .auto-active::before {
            animation-play-state: paused !important;
        }

        &::after,
        &::before {
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            z-index: 1;
            pointer-events: none;
            @include app-bp-md(width, 40px, 28px);
        }

        &::before {
            @include app-bp-md(width, 28px, 18px);
        }

        @include ltr {
            &::after {
                @include app-bp-md(right, 32px, 0);
                background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            }

            &::before {
                @include app-bp-md(left, 32px, 0);
                background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            }
        }

        @include rtl {
            &::after {
                @include app-bp-md(left, 32px, 0);
                background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            }
            &::before {
                @include app-bp-md(right, 32px, 0);
                background: linear-gradient(-90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
            }
        }

        .list-items {
            min-width: fit-content;
            background-color: #fff;
            border: 1.5px solid $dark6;
            border-radius: 20px;
            padding: 8px 20px 6px 20px;
            cursor: pointer;
            overflow: hidden;
            position: relative;

            > p {
                user-select: none;
            }

            &.auto-active {
                background-color: $primary;
                > p {
                    color: #fff;
                    z-index: 10;
                }

                @include app-bp(md) {
                    &::before {
                        position: absolute;
                        content: '';
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba($color: #ffffff, $alpha: 0.25);
                        animation: opacityChange 10s linear;
                        @include rtl {
                            left: 0;
                        }
                        @include ltr {
                            right: 0;
                        }
                    }
                }
            }
            @keyframes opacityChange {
                0% {
                    width: 100%;
                }
                100% {
                    width: 0%;
                }
            }
            @keyframes colorChange {
                0% {
                    color: $dark3;
                }
                100% {
                    color: #fff;
                }
            }
        }
    }

    .child-category {
        overflow-x: auto;
        overflow-y: hidden;
        gap: 32px;
        height: 54px;
        border-radius: 20px;
        border: 1.5px solid rgba($dark5, 0.25);
        background-color: white;

        .list-items {
            min-width: fit-content;
            position: relative;
            transition: 0.3s;
            border-radius: 12px;
            background-color: white;
            cursor: pointer;

            > p {
                color: $primary;
                user-select: none;
            }

            &.active {
                background-color: $primary;
                padding: 4px 8px;

                > p {
                    color: white;
                }
            }

            &:not(:last-child)::after {
                content: '';
                position: absolute;
                width: 1px;
                height: 14px;
                top: 14px;
                background-color: rgba($dark5, 0.25);
                left: -24px;

                @include ltr {
                    left: unset;
                    right: -24px;
                }
            }
        }
    }
}
