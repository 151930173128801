@import 'utilities/references.scss';

.container {
    > p {
        text-align: justify;
    }

    .header-faq {
        @include app-pre-bp(md) {
            justify-content: center !important;
        }
    }
    .collapse-layout {
        margin: -20px;

        @include app-pre-bp(md) {
            flex-direction: column;
            gap: 0;
        }

        .faq-collapse {
            padding: 0 !important;
            flex: 1;

            .faq-item {
                margin: 20px 10px;

                &:last-child {
                    border: 1.5px solid rgba($dark5, 0.25) !important;
                }
                > div:first-child {
                    padding: 12px 32px 12px 24px !important;
                    align-items: center;

                    @include ltr {
                        padding: 12px 24px 12px 32px !important;
                    }
                }

                > div:last-child {
                    border: none !important;
                    background-color: white !important;
                }
                div[class~='ant-collapse-content-box'] {
                    padding: 0 32px 20px !important;
                }
            }
        }
    }
}
